import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Image from '../components/image';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { CircleChevronRightFill, CheckBoxFill } from 'akar-icons';

const DealersPage = () => {
    const titlestrokeani = {
        duration: 1.65,
        ease: [0.19, 1.0, 0.22, 1.0],
        delay: 1,
    };

    return (
        <Layout>
            <Seo title="Dealers" description="Contact Quokka Finance" />
            <div className="app-title">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.45,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="title">
                            <h1>Dealers</h1>
                        </div>
                    </motion.div>
                </div>
                <div className="titlestroke">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        viewBox="0 0 1983.911 218.409"
                    >
                        <motion.path
                            d="M7.57 7.57s427.09 139.916 918.524 188.554c355.142 35.149 744.9 22.1 1028.069-128.2"
                            fill="none"
                            stroke="#c2d83d"
                            strokeLinecap="round"
                            strokeWidth="12"
                            initial={{ pathLength: 0 }}
                            animate={{ pathLength: 1 }}
                            transition={titlestrokeani}
                        />
                    </svg>
                    <motion.div
                        initial={{ offsetDistance: '0%', scale: 2.5 }}
                        animate={{ offsetDistance: '100%', scale: 1 }}
                        transition={titlestrokeani}
                    />
                </div>
            </div>
            <div className="app-vendor">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.85,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="fwitem">
                            <p>
                                The addition of a Vendor Finance option in your
                                equipment sales program will enhance and promote
                                sales opportunities. By partnering with selected
                                Machinery, Equipment, and Motor Vehicle dealers,
                                Quokka Finance powered by Quantum Business
                                Finance helps vendors to:
                            </p>
                            <ul className="vendorlist">
                                <li>
                                    <CheckBoxFill strokeWidth={2} size={36} />
                                    Maximise Sales Opportunities
                                </li>
                                <li>
                                    <CheckBoxFill strokeWidth={2} size={36} />
                                    Control The Sale
                                </li>
                                <li>
                                    <CheckBoxFill strokeWidth={2} size={36} />
                                    Beat The Competition
                                </li>
                                <li>
                                    <CheckBoxFill strokeWidth={2} size={36} />
                                    Additional Revenue Stream
                                </li>
                            </ul>
                            <p>
                                Rather than selling the equipment on the basis
                                of price, we assist your sales staff to present
                                the equipment cost as a monthly outgoing,
                                allowing the customer to quickly quantify the
                                monthly payment against the monthly production
                                benefit that the machine will provide.
                            </p>
                            <p>
                                <strong>
                                    This is not about selling finance - it's
                                    about using point of sale finance as an
                                    effective sales tool.
                                </strong>
                            </p>
                            <p>
                                Quokka Finance works with a wide range of
                                lenders which means an even wider range of
                                products and innovative solutions to suit each
                                vendor's particular requirements.
                            </p>
                            <p className="breaker">
                                <Link to="/quote" className="btn">
                                    Get a Quote{' '}
                                    <CircleChevronRightFill
                                        strokeWidth={2}
                                        size={35}
                                    />
                                </Link>
                            </p>
                        </div>
                    </motion.div>
                </div>
            </div>
            <div className="app-img">
                <div className="wrapper">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        transition={{
                            duration: 0.85,
                            delay: 0.45,
                            ease: [0.23, 0.46, 0.4, 1],
                        }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: -15 },
                        }}
                    >
                        <div className="testi">
                            <div className="testi__item">
                                <div>
                                    <h2>
                                        Quokka Finance Vendor Finance Option
                                    </h2>
                                    <p>
                                        The addition of a Vendor Finance option
                                        in your equipment sales program will
                                        enhance and promote sales opportunities
                                    </p>
                                </div>
                            </div>
                            <Image
                                src="qu-partner.png"
                                alt="Quokka Finance Vendors"
                            />
                        </div>
                    </motion.div>
                </div>
            </div>
        </Layout>
    );
};

export default DealersPage;
